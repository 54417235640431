import styled from "@emotion/styled"
import { Grid, Stack, Typography } from "@mui/material"
import { COLORS } from "../../../../constants/base"
import breakfastIcon from '../assets/breakfast.png';
import lunchIcon from '../assets/lunch.png';
import dinnerIcon from '../assets/dinner.png';

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${COLORS.gray_light};
  border-radius: 8px;
  padding: 10px;
`

export const SERVICE_TIME = [
  {
    title: 'Breakfast',
    time: '07.00-11.00',
    icon: breakfastIcon,
    getPeakDay: data => data.peakBreakfast || 'N/A',
    getValue: data => data.breakfast || 0,
  },
  {
    title: 'Lunch',
    time: '12.00-14.00',
    icon: lunchIcon,
    getPeakDay: data => data.peakLunch || 'N/A',
    getValue: data => data.lunch || 0,
  },
  {
    title: 'Dinner',
    time: '17.00-21.00',
    icon: dinnerIcon,
    getPeakDay: data => data.peakDinner || 'N/A',
    getValue: data => data.dinner || 0,
  },
]

const Time = ({ icon, title, time }) => {
  return (
    <Stack alignItems="center" spacing={4}>
      <Typography
        textOverflow="ellipsis"
        variant="subtitle"
        whiteSpace="nowrap"
        overflow="hidden"
        component="h3"
      >
        {title}
      </Typography>
      <Typography
        textOverflow="ellipsis"
        overflow="hidden"
        variant="subtitle1" 
        color={COLORS.inputBorder}
      >
        {time}
      </Typography>
      <IconWrapper>
      <img src={icon} width={48} height={48} alt="time" />
      </IconWrapper>
    </Stack>
  )
}

const ServiceTime = () => {
  return (
    <Grid container>
      <Grid item xs={3}></Grid>
      {SERVICE_TIME.map(item => (
       <Grid item key={item.title} xs={3}>
        <Time {...item} />
       </Grid> 
      ))}
    </Grid>
  )
}

export default ServiceTime;
