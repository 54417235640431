import React, {useMemo} from 'react';
import Base from "../Base";
import {useSelector} from "react-redux";
import {selectExplore} from "../../../redux/explore";
import {Stack, Typography} from "@mui/material";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import {WIDGET_IDENTITY} from "../config";
import SimpleDialog from "../../dialogs/SimpleDialog";
import FocusFoodForm from "./components/FocusFoodForm";
import useIsMobile from "../../../hooks/useIsMobile";
import {useModal} from "mui-modal-provider";
import dayjs from "dayjs";
import {selectUser} from "../../../redux/user";
import {formatDataQuery} from "../../../utils/date/formatDataQuery";
import Loader from "../Loader";
import Food from "./components/Food";
import styled from "@emotion/styled";
import {COLORS} from "../../../constants/base";
import {LABELS, METRICS} from "../../../constants/explore";
import { mepMetricParams } from '../../explore/helpers'
import {useWidget} from "../useWidget";

const TITLE_MOBILE = 'Focus Foods'
const startOfCurrentMonth = dayjs().startOf('month').format('YYYY-MM-DD')

const Placeholder = styled(Typography)`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
`

const cacheKey = 'focus-food'
const FocusFood = () => {
  const { filters } = useSelector(selectExplore)
  const { id } = useSelector(selectUser)
  const selectedSite = filters.sites[0]
  const navigate = useNavigate()
  const isMobile = useIsMobile()
  const { showModal } = useModal()
  const isLogs = filters.metric === METRICS.wasteLogs

  const params = useMemo(() => (
    {
      startDate: startOfCurrentMonth,
      endDate: formatDataQuery(new Date()),
      metric: mepMetricParams[filters.metric],
      ...(selectedSite?.id ? { site: selectedSite.id} : {}),
    }
  ), [filters.metric, selectedSite?.id])

  const { data, isLoading, goToExplore, title, tip, updateCache } = useWidget(WIDGET_IDENTITY.FOCUS_FOOD, {
    fetchKey: id ? [`api/overview/${id}/focus_foods/`, params] : null,
    exploreParams: {
      params: {
        ...filters,
        dateRange: [
          startOfCurrentMonth,
          new Date(),
        ],
        labels: LABELS.focusFood,
      },
      path: '/insights/foods',
    }
  })

  const showFoodModal = () => {
    const targetModal =  showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: <FocusFoodForm focusFood={data || []} closeModal={() => {
        targetModal.hide()
        updateCache()
      }} />,
    })
  }
  
  return (
    <Base title={isMobile ? TITLE_MOBILE : title} tooltip={tip} id={WIDGET_IDENTITY.FOCUS_FOOD}>
      <Base.Content style={{ minHeight: '280px', position: 'relative' }}>
        {isLoading && <Loader />}
        {data && (
          <Stack spacing={4}>
            {data?.map(({ id, ...rest}) => (
              <Food key={id} {...rest} metric={filters.metric} />
            ))}
          </Stack>
        )}
        {data?.length === 0 && !isLoading && (
          <Placeholder text="No Data"/>
        )}
      </Base.Content>
      <Base.Footer>
        <Stack direction="row" justifyContent={isLogs ? 'flex-end' : 'space-between'}>
          {!isLogs &&  <Button disabled={isLoading} onClick={showFoodModal} variant="link">Edit Focus Foods</Button>}
          <Button disabled={isLoading} variant="link" onClick={goToExplore}>Explore Focus Foods</Button>
        </Stack>
      </Base.Footer>
    </Base>
  );
};

export default FocusFood;
