import React, { useRef } from 'react';
import {Bar, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {renderLegend, renderTooltip} from "./renders";
import {COLORS} from "../../../constants/base";
import {formatTicker} from "../helpers";

const LineChar = ({ data, activeLabels, toggleActiveLabel, unit, duration, ticks, direction, formatValues }) => {
  const allLabels = Object.values(activeLabels)
  const activeBars = allLabels.filter(({ isActive }) => isActive)
  const height = 300 + 400
  const isVertical = direction === 'vertical'
  const scroll = useRef(0)

  const handleTogglelabel = (position) => (value) => {
    scroll.current = position
    toggleActiveLabel(value)
  }

  return (
    <ResponsiveContainer key={data.length} width="100%" height="100%" minHeight={Math.max(600, height)}>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          bottom: 40,
          right: 40,
        }}
      >
        <XAxis fontSize={10} dataKey="x" stroke={COLORS.gray_light} angle={45} textAnchor="start" tickFormatter={formatTicker(duration)} {...ticks} />
        <YAxis fontSize={10} stroke={COLORS.gray_light} />
        {!!activeBars.length && <Tooltip wrapperStyle={{ pointerEvents: 'auto' }} position={isVertical ? {x: 0} : undefined } content={renderTooltip(activeLabels, unit, activeBars, undefined, duration, formatValues)} />}
        {allLabels.length > 1 && <Legend wrapperStyle={{ width: isVertical ? 'inherit' : 'unset' }} content={renderLegend(activeLabels, handleTogglelabel, scroll.current)} />}
        {activeBars.map(({key, color}, index, arr) => (
          <Line
          key={key}
          type="monotone"
          dataKey={key}
          stroke={color}
          activeDot={{ r: 8 }}
          dot={false}
      />
        ))}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChar;
