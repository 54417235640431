import React, { useMemo } from 'react';
import {Box, Stack, Typography} from "@mui/material";
import {
  CHART_TYPE,
  LABELS,
  LABELS_LABEL,
  METRICS,
  METRICS_LABEL,
  TRIMMINGS_COLOR_MAP
} from "../../../constants/explore";
import {COLORS} from "../../../constants/base";
import {useExploreFilters} from "../useExploreFilters";
import Bar from "./Bar";
import { exploreDataToChartData, exploreDataToTableData } from "./helpers";
import Line from './Line';
import TableView from './Table';
import useSWR from "swr";
import {filtersToExploreParams, formatChartValues, getTickConfig, getTotal} from "../helpers";
import {fetcher} from "../../../utils/axios";
import numberFormater from "../../../utils/formaters/numberFormater";
import CircularLoading from "../../progress/CircularLoading";
import {useChartLabels} from "../../../hooks/useChartLabels";
import useIsMobile from "../../../hooks/useIsMobile";
import {useDispatch} from "react-redux";
import { updateData } from "../../../redux/explore";
import {Alert} from "../../styled/GlobalStyled";
import {formatCoastValue} from "../../../utils/formaters/formatCoastValue";

const CHARTS_MAP = {
  [CHART_TYPE.bar]: Bar,
  [CHART_TYPE.line]: Line,
  [CHART_TYPE.table]: TableView,
}
const MAPPERS_MAP = {
  [CHART_TYPE.bar]: exploreDataToChartData,
  [CHART_TYPE.line]: exploreDataToChartData,
  [CHART_TYPE.table]: exploreDataToTableData,
}
const preSelectedLabels = ['Ingredients - Protein', 'Ingredients - Fruit & Veg' ]
const preSelectedFoodLabel = ['Food']
const preselectedSubcategoryLabels = [
  'Dairy & Eggs',
  'Fish & Seafood',
  'Meat',
  'Vegetables & Mushrooms',
  'Legumes',
  'Fruits & Berries',
]

const getPredefinedOptions = list => () => (key) => {
  return {
    isActive: list.includes(key)
  }
}
const getColorForTrimmings = () => (key) => ({
  color: TRIMMINGS_COLOR_MAP[key] || COLORS.brandYellow,
})

const preSelectPlateMonitors = filters => (_, meta) => ({
  isActive: filters.platesWasteOnly ? meta.type === 'PLATE_WASTE' : true,
})

const predefinedOptionsByLabel = {
  [LABELS.category]: getPredefinedOptions(preSelectedLabels),
  [LABELS.trimmings]: getColorForTrimmings,
  [LABELS.nonFood]: getPredefinedOptions(preSelectedFoodLabel),
  [LABELS.subcategory]: getPredefinedOptions(preselectedSubcategoryLabels),
  [LABELS.monitors]: preSelectPlateMonitors,
}

const fieldByMetric = {
  [METRICS.occupancy]: 'Occupancy',
}

const TIP = "Consider changing the chart's date range to improve visibility"


const Chart = () => {
  const { filters } = useExploreFilters()
  const dispatch = useDispatch()
  const Report = CHARTS_MAP[filters.chartType]
  const { data: exploreData, isLoading } = useSWR(['api/explorer/drilldown/', filtersToExploreParams(filters)], fetcher, {
    revalidateOnFocus: false,
    keepPreviousData: true,
    onSuccess: data => dispatch(updateData(data))
  })

  const { total_waste: totalWaste, unit, chart} = exploreData || {}
  const canShowChart = chart && !!chart.length
  const dataMapper = MAPPERS_MAP[filters.chartType]
  const fieldName = fieldByMetric[filters.metric]
  const chartData = useMemo(() => chart ? dataMapper(chart, fieldName) : [], [chart, dataMapper])
  const isMobile = useIsMobile()
  const chartDirection = isMobile ? 'vertical' : 'horizontal'
  
  const predefinedLabelProps = useMemo( () => {
    if (predefinedOptionsByLabel[filters.labels]) {
      return predefinedOptionsByLabel[filters.labels](filters)
    }
  }, [filters.labels])

  const { activeLabels, toggleLabel } = useChartLabels(
    chartData,
    ['x', 'meta', 'diff'],
    LABELS_LABEL[filters.labels],
    predefinedLabelProps,
    )
  const totalByLabels = useMemo(() => {
    try {
      if(filters.chartType === CHART_TYPE.table) {
        return totalWaste
      }
      return getTotal(chartData, activeLabels)
    }catch (e) {
      return totalWaste
    }
  }, [chartData, activeLabels])

  const showTip = chartData.length > 100 && filters.chartType === CHART_TYPE.bar && !isMobile

  const total = numberFormater({ value: totalByLabels, after: unit })
  const ticks = useMemo(() => {
    return getTickConfig(chartData,filters.dateGranularity)
  }, [chartData, filters.dateGranularity])
  return (
    <Stack id='explore-dashboard' sx={{ height: '100%', minHeight: '400px', width: '100%' }}>
      {canShowChart && (
        <div>
          <Typography variant="subtitle1" color={COLORS.inputBorder}>{METRICS_LABEL[filters.metric]}</Typography>
          <Typography color={COLORS.darkBlue2} variant="large2">{formatCoastValue(total)}</Typography>
        </div>
      )}
      {showTip && (
        <Box position="absolute" top={-40} left="0">
          <Alert py={0} severity="info">{TIP}</Alert>
        </Box>
      )}
      <Box sx={{flex: 1}}>
        <CircularLoading loading={isLoading}>
          {canShowChart && (
            <Report
              data={chartData}
              activeLabels={activeLabels}
              toggleActiveLabel={toggleLabel}
              unit={unit}
              direction={chartDirection}
              xKey="x"
              duration={filters.dateGranularity}
              ticks={ticks}
              formatValues={formatChartValues}
            />
          )}
          {!canShowChart && (
            <Stack justifyContent="center" alignItems="center" height="100%">
              <Typography variant="large2" color={COLORS.inputBorder}>No Data</Typography>
            </Stack>
          )}
        </CircularLoading>
      </Box>
    </Stack>
  );
};

export default Chart;
