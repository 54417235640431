import MonthlySnapShot from "./MonthlySnapshot";
import FocusFood from './FocusFood'
import Occupancy from './Occupancy'
import { WIDGET_IDENTITY } from "./config";
import JourneyToDate from "./JourneyToDate";
import Trimmings from "./Trimmings";
import MostDailyWaste from "./MostDailyWaste";
import { MiniPlateWaste } from "./MiniPlateWaste/MiniPlateWste";
import DailyPlateWaste from "./DailyPlateWaste";
export default {
  [WIDGET_IDENTITY.MONTHLY_SNAPSHOT]: MonthlySnapShot,
  [WIDGET_IDENTITY.FOCUS_FOOD]: FocusFood,
  [WIDGET_IDENTITY.OCCUPANCY]: Occupancy,
  [WIDGET_IDENTITY.JOURNEY]: JourneyToDate,
  [WIDGET_IDENTITY.TRIMMINGS]: Trimmings,
  [WIDGET_IDENTITY.MOST_WASTED_GROUPS]: MostDailyWaste,
  [WIDGET_IDENTITY.MINI_PLATE_WASTE]: MiniPlateWaste,
  [WIDGET_IDENTITY.DAILY_PLATE_WASTE]: DailyPlateWaste,
}
