import React, {useEffect, useMemo, useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Grid,
  Stack,
} from "@mui/material";

import { fetchZones } from "../../redux/zones";
import { selectUser } from "../../redux/user";
import Header from "../../components/overview/Header";
import useMixpanelTrack from "../../hooks/useMixpanelTrack";
import { fetchProfile, selectProfile } from "../../redux/profile";
import Widgets from "../../components/widgets";
import AddWidget from "../../components/widgets/AddWidget";
import {selectPinnedWidgets} from "../../redux/widgets";
import MonthlySnapShot from "../../components/widgets/MonthlySnapshot";
import FocusFood from "../../components/widgets/FocusFood";
import JourneyToDate from "../../components/widgets/JourneyToDate";
import {init, resetFilters, selectExplore} from "../../redux/explore";
import {useLocation} from "react-router-dom";
import useAppSelector from "../../hooks/useAppSelector";
import qs from "qs";
import {withSites} from "../../hoc/withSites";
import CircularLoading from "../../components/progress/CircularLoading";
import {OVERVIEW_AVAILABLE_METRICS} from "../../constants/home";
import {METRICS} from "../../constants/explore";
import {pipe} from "../../utils/pipe";
import Page from "../../layouts/Page";

const ensureMetric = params => ({
  ...params,
  metric: OVERVIEW_AVAILABLE_METRICS.includes(params.metric) ? params.metric : METRICS.totalWaste
})

function Overview() {
  const dispatch = useDispatch();
  const mixpanelTrack = useMixpanelTrack();
  const profile = useSelector(selectProfile);
  const user = useSelector(selectUser)
  const pinnedWidgets = useSelector(selectPinnedWidgets)
  const location = useLocation()
  const { isLoading } = useAppSelector(selectExplore)
  const filtersFromQuery = useMemo(
    () => qs.parse(location.search, { ignoreQueryPrefix: true, arrayFormat: 'indices' }),
    [location.search]
  )
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (!profile.data.id && user.id) {
      dispatch(fetchProfile(user.id));
    }
  }, [user.id]);

  useEffect(() => {
    dispatch(init({ params: pipe(filtersFromQuery, ensureMetric), shouldFetchFood: false }))
      .then(() => setIsReady(true))
    mixpanelTrack("Overview - Page View");

  }, []);

  return (
    <CircularLoading loading={isLoading || !isReady}>
      <Page title="Overview">
        <Stack gap={8}>
          <Header />
          <Grid container spacing={4} alignItems="stretch">
            <Grid item xs={12}>
              <MonthlySnapShot />
            </Grid>
            <Grid item xs={12} md={6}>
              <FocusFood />
            </Grid>
            <Grid item xs={12} md={6}>
              <JourneyToDate />
            </Grid>
            {pinnedWidgets.map(id => {
              const Widget = Widgets[id]
              if (Widget) {
                return (
                  <Grid key={id} item xs={12} md={6}>
                    <Widget />
                  </Grid>
                )
              }
             return null
            })}
            <Grid item xs={12} md={6}>
              <AddWidget />
            </Grid>
          </Grid>
        </Stack>
      </Page>
    </CircularLoading>
  );
}

export default withSites(Overview);
