import saladIcon from "./assets/salad.png";
import pieIcon from "./assets/pie-chart.png";
import binIcon from "./assets/bin.png";
import plateWaste from "./assets/food-waste.png";
import { v4 as uuidv4 } from 'uuid';

export const STATS = [
  {
    id: uuidv4(),
    getTitle: (data) => data.totalWaste,
    description: "Total Plate Waste This Month so Far",
    icon: plateWaste,
    getDiff: (data) => data.totalWasteDiff,
  },
  {
    id: uuidv4(),
    getTitle: (data) => data.mealsWaste,
    description: "Total Meals Wasted",
    icon: saladIcon,
    getDiff: () => {},
  },
  {
    id: uuidv4(),
    getTitle: (data) => data.plateWaste,
    description: "Plate Waste % of Total Waste",
    icon: pieIcon,
    getDiff: (data) => data.plateWasteDiff,
  },
  {
    id: uuidv4(),
    getTitle: (data) => data.activeMonitors,
    description: "Active Plate Waste Monitors",
    icon: binIcon,
    getDiff: () => {},
  },
];

export const MAX_VERTICAL_SIZE = 780;
