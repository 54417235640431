import React, { useEffect, useMemo } from "react";

import PropTypes from "prop-types";
import { THEMES } from "../constants/base";

const initialState = {
  theme: THEMES.DEFAULT,
  setTheme: () => {},
};

const ThemeContext = React.createContext(initialState);

function ThemeProvider({ children }) {
  const [currentTheme, _setTheme] = React.useState(initialState.theme);

  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");

    if (storedTheme) {
      try {
        _setTheme(JSON.parse(storedTheme));
      } catch(e) { }
    }
  }, []);

  const setTheme = (theme) => {
    localStorage.setItem("theme", JSON.stringify(theme));
    _setTheme(theme);
  };

  const value = useMemo(() => {
    return { theme: currentTheme, setTheme };
  }, [currentTheme]);

  return (
    <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

ThemeProvider.defaultProps = {
  children: undefined,
};

export { ThemeProvider, ThemeContext };
