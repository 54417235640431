import Base from "../Base";
import { WIDGET_IDENTITY } from "../config";
import { useWidget } from "../useWidget";
import { Stack, Button } from "@mui/material";
import ServiceTime from "./components/ServiceTime";
import WasteGrid from "./components/WasteGrid";
import { formatDataQuery } from "../../../utils/date/formatDataQuery";
import { mepMetricParams } from "../../explore/helpers";
import { selectExplore } from "../../../redux/explore";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { getAvgWaste, getPeakDay, mapData } from "./helpers";
import Loader from "../Loader";
import { CHART_TYPE, DATE_GRAN, LABELS } from "../../../constants/explore";
import { startOfCurrentMonth } from "../../../constants/widgets";

const DailyPlateWaste = () => {
  const { filters } = useSelector(selectExplore)
  const selectedSite = filters.sites[0]?.id
  const params = {
    site: selectedSite,
    startDate: formatDataQuery(startOfCurrentMonth),
    endDate: formatDataQuery(new Date()),
    metric:  mepMetricParams[filters.metric]
  }

  const { data, isLoading, title, tip, goToExplore } = useWidget(WIDGET_IDENTITY.DAILY_PLATE_WASTE, {
    fetchKey: ['api/overview/daily_plate_waste/', params],
    exploreParams: {
      params: {
        ...filters,
        dateRange: [
          formatDataQuery(startOfCurrentMonth),
          formatDataQuery(new Date()),
        ],
        dateGranularity: DATE_GRAN.daily,
        labels: LABELS.monitors,
        chartType: CHART_TYPE.bar,
        platesWasteOnly: true,
      },
      path: '/explore',
    }
  })
  const mappedData = mapData(data)
  const averageWaste = getAvgWaste(mappedData)
  const peakWeekDays = getPeakDay(mappedData)

  return (
    <Base canUnpin title={title} tooltip={tip} id={WIDGET_IDENTITY.DAILY_PLATE_WASTE}>
      <Base.Content>
      {isLoading && <Loader />}
      {data && (
        <Stack spacing={4}>
          <ServiceTime />
          <WasteGrid title="Daily Average" data={averageWaste} />
          <WasteGrid title="Peak Weekday This Month" data={peakWeekDays} />
        </Stack>
      )}
      </Base.Content>
      <Base.Footer>
        <Stack direction="row" justifyContent="flex-end">
          <Button onClick={goToExplore} disabled={isLoading} variant="link">Explore Daily Plate Waste</Button>
        </Stack>
      </Base.Footer>
    </Base>
  )
}

export default DailyPlateWaste;
