import { Stack, Button } from "@mui/material";
const ResetButton = ({ onClick }) => (
  <Stack direction="row" justifyContent="flex-end">
    <Button
      variant="text"
      onClick={onClick}
    >
      Clear
    </Button>
  </Stack>
)

export default ResetButton;
