import { Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { selectExplore } from "../../../../redux/explore";
import Food from "../../FocusFood/components/Food";

const Chart = ({ data, unit }) => {
  const { filters } = useSelector(selectExplore);
  return (
    <Stack spacing={4} sx={{ width: "100%" }}>
      {data?.map(({ id, ...rest }) => (
        <Food key={id} {...rest} metric={filters.metric} unit={unit} noImage />
      ))}
    </Stack>
  );
};
export default Chart;
