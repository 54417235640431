import { maxWidth } from "@mui/system";
import { COLORS } from "../constants/base";

const components = {
  MuiButtonBase: {
    defaultProps: {
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        '&.MuiBottomNavigationAction-root, &.MuiBottomNavigationAction-root.Mui-selected': {
          color: 'white',
        },
      }
    }
  },
  MuiButton: {
    styleOverrides: {
      root: {
        fontWeight: 600,
        borderRadius: '16px',
        padding: '14px 24px'
      },
      containedPrimary: {
        color: COLORS.white,
        backgroundColor: COLORS.accent_blue,
        "&:hover": {
          backgroundColor: COLORS.blueHover,
        },
        "&:active": {
          backgroundColor: COLORS.bluePressed,
        },
      },
      containedSecondary: {
        backgroundColor: COLORS.brandYellow,
        color: COLORS.black,
        "&:hover": {
          backgroundColor: COLORS.yellowHower,
        },
        "&:active": {
          backgroundColor: COLORS.yellowPressed,
        },
      },
      outlinedError: {
        color: COLORS.red,
        backgroundColor: COLORS.white,
        borderColor: COLORS.red,
        "&:hover": {
          color: COLORS.redHover,
          borderColor: COLORS.redHover,
        },
        "&:active": {
          color: COLORS.redPressed,
          borderColor: COLORS.redPressed,
        },
      },
      textPrimary: {
        color: COLORS.darkBlue2,
        backgroundColor: "transparent",
        "&:hover": {
          color: COLORS.accentBlue,
          backgroundColor: "transparent",
        },
        "&:active": {
          color: COLORS.bluePressed,
        },
        padding: '0 !important',
      },
      containedSuccess: {
        backgroundColor: COLORS.green,
        "&:hover": {
          backgroundColor: COLORS.greenHower,
        },
        "&:active": {
          backgroundColor: COLORS.greenPressed,
        },
      },
      containedError: {
        backgroundColor: COLORS.red,
        "&:hover": {
          backgroundColor: COLORS.redHover,
        },
        "&:active": {
          backgroundColor: COLORS.redPressed,
        },
      },
      containedWarning: {
        backgroundColor: COLORS.yellow,
        "&:hover": {
          backgroundColor: COLORS.yellowHower,
        },
        "&:active": {
          backgroundColor: COLORS.yellowPressed,
        },
      },
      containedNegative: {
        "&:active": {
          backgroundColor: COLORS.gray_light,
        },
        boxShadow: 'none',
      },
      outlinedPrimary: {
        backgroundColor: COLORS.white,
        borderColor: COLORS.inputBorder,
      },
      sizeMedium: {
        padding: '10px 36px'
      },
      sizeSmall: {
        padding: '10px 16px'
      },
      sizeExtraSmall: {
        padding: '6px',
        minWidth: '0',
      },
      linkPrimary: {
        background: 'none',
        color: COLORS.inputBorder,
        padding: '0 !important',
        textDecoration: 'underline',
        minWidth: '0 !important',
        '&:hover': {
          background: 'none'
        }
      }
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: {
        color: COLORS.darkBlue2,
        fontSize: "13px",
        fontWeight: 500,
        "&.Mui-focused": {
          color: COLORS.accent_blue,
        },
        "&.Mui-error": {
          color: COLORS.red,
        },
      },
    },
  },
  MuiFormHelperText: {
    styleOverrides: {
      root: {
        "&.Mui-error": {
          color: `${COLORS.red} !important`,
        },
      },
    },
  },
  MuiInputBase: {
    styleOverrides: {
      root: {
        fontSize: "15px",
        color: COLORS.table_black,
        position: "relative",
        "&::before": {
          borderBottom: "1px solid #d3d8dd !important",
        },
        "&::after": {
          borderBottom: `1px solid ${COLORS.gray} !important`,
        },
        "&.Mui-error": {
          "&::after": {
            borderBottomColor: `${COLORS.red} !important`,
          },
        },
        "&:hover:not(.Mui-disabled):before": {
          borderBottom: "1px solid #d3d8dd !important",
        },
      },
    },
  },
  MuiOutlinedInput: {
    styleOverrides: {
      root: {
        borderRadius: '16px',
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.inputBorderHover
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: COLORS.accentBlue,
        }
      },
      input: {
        '&::placeholder': {
          color: COLORS.inputBorder,
        }
      },
      notchedOutline: {
        top: 0,
        borderRadius: '16px',
        borderColor: COLORS.inputBorder,
        '& legend': {
          display: 'none'
        },
      },
    }
  },
  MuiInputLabel: {
    styleOverrides: {
      root: {
        color: COLORS.gray,
        fontSize: "13px",
        fontWeight: 500,
        "&.Mui-focused": {
          color: COLORS.accent_blue,
        },
        "&.Mui-error": {
          color: COLORS.red,
        },
        '&.MuiInputLabel-outlined': {
          display: 'inline',
          marginBottom: '5px',
          width: 'fit-content',
          position: 'relative',
          transform: 'none',
          color: COLORS.darkBlue2,
          maxWidth: '100% !important',
        }
      },
    },
  },
  MuiSelect: {
    styleOverrides: {
      root: {
        fontSize: "15px",
        margin: "7px 0",
        "&.MuiInput-underline::before": {
          borderBottom: "1px solid #d3d8dd",
        },
        "&.MuiInput-underline::after": {
          borderBottom: `1px solid ${COLORS.gray}`,
        },
        "&.MuiInput-underline:hover:not(.Mui-disabled):before": {
          borderBottom: "1px solid #d3d8dd",
        },
        "&.Mui-focused .MuiSvgIcon-root": {
          color: COLORS.accent_blue,
        },
      },
      outlined: {
        '& ~ fieldset legend': {
          display: 'block',
          height: 0,
          '& span': {
            opacity: 1,
            position: 'absolute',
            overflow: 'visible',
            transform: 'translate(0, -50%)',
            top: '50%',
            fontSize: '14px',
            color: COLORS.gray,
          }
        }
      },
    },
  },
  MuiTextField: {
    styleOverrides: {
      root: {
        margin: "7px 0",
      },
    },
  },
  MuiLink: {
    defaultProps: {
      underline: "hover",
    },
  },
  MuiCardHeader: {
    defaultProps: {
      titleTypographyProps: {
        variant: "h6",
      },
    },
    styleOverrides: {
      action: {
        marginTop: "-4px",
        marginRight: "-4px",
      },
    },
  },
  MuiCard: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
        boxShadow:
          "rgba(50, 50, 93, 0.025) 0px 2px 5px -1px, rgba(0, 0, 0, 0.05) 0px 1px 3px -1px",
        backgroundImage: "none",
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundImage: "none",
        borderRadius: "10px",
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      day: {
        fontWeight: "300",
      },
    },
  },
  MuiPickersYear: {
    styleOverrides: {
      root: {
        height: "64px",
      },
    },
  },
  MuiPickersCalendar: {
    styleOverrides: {
      transitionContainer: {
        marginTop: "6px",
      },
    },
  },
  MuiPickersCalendarHeader: {
    styleOverrides: {
      iconButton: {
        backgroundColor: "transparent",
        "& > *": {
          backgroundColor: "transparent",
        },
      },
      switchHeader: {
        marginTop: "2px",
        marginBottom: "4px",
      },
    },
  },
  MuiPickersClock: {
    styleOverrides: {
      container: {
        margin: `32px 0 4px`,
      },
    },
  },
  MuiPickersClockNumber: {
    styleOverrides: {
      clockNumber: {
        left: `calc(50% - 16px)`,
        width: "32px",
        height: "32px",
      },
    },
  },
  MuiPickerDTHeader: {
    styleOverrides: {
      dateHeader: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
      timeHeader: {
        "& h3": {
          fontSize: "3rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiPickersTimePicker: {
    styleOverrides: {
      hourMinuteLabel: {
        "& h2": {
          fontSize: "3.75rem",
          fontWeight: 300,
        },
      },
    },
  },
  MuiPickersToolbar: {
    styleOverrides: {
      toolbar: {
        "& h4": {
          fontSize: "2.125rem",
          fontWeight: 400,
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: "6px",
      },
      colorPrimary: {
        backgroundColor: "#2ED47A",
      },
      colorError: {
        backgroundColor: "#F7685B",
      },
      colorWarning: {
        backgroundColor: "#FFB946",
      },
      colorInfo: {
        backgroundColor: COLORS.accentBlue,
        borderColor: COLORS.accentBlue,
        color: COLORS.white,
      },
      colorSuccess: {
        borderColor: COLORS.green,
        color: COLORS.green,
      },
    },
  },
  MuiTypography: {
    styleOverrides: {
      large: {
        fontSize: "56px",
        lineHeight: "84px",
        letterSpacing: "0px",
        fontWeight: "medium",
      },
      medium: {
        fontSize: "24px",
        lineHeight: "32px",
        letterSpacing: "0px",
        fontWeight: 700,
      },
      h1: {
        fontSize: "20px",
        lineHeight: "30px",
        letterSpacing: "0.1px",
        fontWeight: "medium",
      },
      h2: {
        fontSize: "18px",
        lineHeight: "28px",
        letterSpacing: "0.1px",
        fontWeight: "medium",
      },
      h3: {
        fontSize: "15px",
        lineHeight: "23px",
        letterSpacing: "0.1px",
        fontWeight: "medium",
      },
      h6: {
        fontStyle: "normal",
        fontWeight: 500,
        fontSize: "20px",
        lineHeight: "30px",
        letterSpacing: "0.15px",
      },
      subtitle1: {
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0px",
        fontWeight: "medium",
      },
      subtitle2: {
        fontSize: "13px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        fontWeight: "medium",
      },
      button: {
        fontSize: "13px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        fontWeight: "semibold",
      },
    },
  },
  MuiDataGrid: {
    styleOverrides: {
      columnSeparator: {
        display: "none",
      },
      columnHeaderTitle: {
        color: COLORS.table_gray,
        fontWeight: 500,
        fontSize: "13px",
      },
    },
  },
  MuiSwitch: {
    styleOverrides: {
      track: {
        colorSecondary: 'red', // Set the custom color for secondary
      },
    }
  }
};

export default components;
