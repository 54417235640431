import { Grid, Typography } from "@mui/material"
import { COLORS } from "../../../../constants/base"

const DailyAvarage = ({data, title}) => {
  return (
    <Grid container alignItems="center" justifyContent="center">
      <Grid item xs={3}>
        <Typography variant="subtitle1" color={COLORS.inputBorder}>{title}</Typography>
      </Grid>
      {data.map(value => (
        <Grid xs={3} item justifyContent="center">
          <Typography
            textOverflow="ellipsis"
            variant="subtitle"
            whiteSpace="nowrap"
            overflow="hidden"
            component="h3"
            textAlign="center"
          >
            {value}
          </Typography>
        </Grid>
      ))}
    </Grid>
  )
}

export default DailyAvarage;
