import { Fragment, useEffect, useState, useMemo } from "react";
import { Button, Divider, Stack, TextField } from "@mui/material";
import BaseModal from "../../../layouts/Modal";
import { getRecommendedTarget } from "../../../utils/widgets/getRecomendedTarget";
import useSWR from "swr";
import { fetcher } from "../../../utils/axios";
import Loader from "../Loader";
import Recommendation from "../components/Recomendation";
import { useFormik } from "formik";
import { baseAxios } from "../../../utils/axios";
import { handleTargetChange } from "../helpers";
import { getThersoldValidationSchema, mapFormData } from "./helpers";
import { selectExplore } from "../../../redux/explore";
import { useChangeTarget } from "../useChangeTarget";
import { useSelector } from "react-redux";
import ResetButton from "./components/ResetButton";

const Field = ({ name, label, value, site, onChange, error, id, onReset, isSeted }) => {
  const [recomendedTarget, setRecomendedTarget] = useState({});
  useEffect(() => {
    getRecommendedTarget({ site, widget: "device", device: id }).then(
      setRecomendedTarget
    );
  }, [site, id]);

  return (
    <Stack spacing={2}>
      <TextField
        name={name}
        InputLabelProps={{ shrink: true }}
        label={label}
        value={value}
        placeholder="Monthly Target"
        onChange={onChange}
        error={error}
        helperText={error}
      />
      {!!isSeted && <ResetButton onClick={onReset} />}
      <Recommendation {...recomendedTarget} />
    </Stack>
  );
};


const SetDailyTargetModal = ({ closeModal }) => {
  const { filters } = useSelector(selectExplore);
  const [validationSchema, setValidationSchema] = useState()
  const siteId = filters.sites[0]?.id || null;
  const fetchKey = useMemo(
    () => ["api/device_waste_targets", { site: siteId }],
    [siteId]
  );
  const [isUpdating, setIsUpdating] = useState(false)

  const { confirm } = useChangeTarget()

  const updateDevices = (value) => {
    setIsUpdating(true)
    const device = Object.values(value)
      .map(item => ({ ...item, threshold: parseFloat(item.threshold)}))
    const payload = {
      device,
      site: siteId
    }

    baseAxios.post("api/devices/set_monthly_target/", payload)
      .then(closeModal)
      .finally(() => setIsUpdating(false))
  }

  const {resetForm, handleChange, values, handleSubmit, dirty, errors, isValid} = useFormik({
    initialValues: {},
    validationSchema,
    onSubmit: confirm(updateDevices),
  })

  const updateFormState = (data) => {
    const payload = mapFormData(data)
    setValidationSchema(getThersoldValidationSchema(payload))
    resetForm({ values: payload })
  }

  const { data, isValidating } = useSWR(fetchKey, fetcher, {
    revalidateOnFocus: false,
    keepPreviousData: false,
    onSuccess: updateFormState,
  });

  const reset = id => () => {
    const payload = {
      ...values,
      [id]: {...values[id], threshold: null},
    }
    updateDevices(payload)
  }

  const canSubmit = isValid && dirty && !isUpdating && !isValidating
  return (
    <BaseModal title="Set Plate Waste Monthly Targets (KG)">
        <form onSubmit={handleSubmit}>
        <Stack spacing={4}>
          {isValidating && <Loader />}
          {data && data.map((monitor) => (
            <Fragment key={monitor.id}>
              <Field
                {...monitor}
                name={`${monitor.id}.threshold`}
                label={monitor.name}
                site={siteId}
                value={values[monitor.id]?.threshold}
                onChange={handleTargetChange(handleChange)}
                error={errors[monitor.id]?.threshold}
                onReset={confirm(reset(monitor.id), { clear: true })}
                isSeted={!!monitor?.threshold}
              />
              <Divider />
            </Fragment>
          ))}
          <Button disabled={!canSubmit} variant="contained" type="submit" color="secondary">
            {isUpdating ? 'Processing...' : 'Set target'}
          </Button>
        </Stack>
        </form>
    </BaseModal>
  );
};

export default SetDailyTargetModal;
