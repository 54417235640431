export const widgets = {
  MONTHLY_SNAPSHOT: "MonthlySnapshot",
  FOCUS_FOOD: "FocusFood",
  OCCUPANCY: "Occupancy",
  JOURNEY: "JourneyToDate",
  TRIMMINGS: "Trimmings",
  DAILY_WASTE: "MostDailyWaste",
};

export const WIDGET_IDENTITY = {
  OCCUPANCY: "occupancy",
  TRIMMINGS: "trimmings",
  MOST_WASTED_GROUPS: "most-wasted-groups",
  MONTHLY_SNAPSHOT: "monthly-snapshot",
  JOURNEY: "journey",
  FOCUS_FOOD: "focus-food",
  MINI_PLATE_WASTE: "mini-plate-waste",
  DAILY_PLATE_WASTE: "daily-plate-waste",
};

export const widgetsConfig = {
  [WIDGET_IDENTITY.OCCUPANCY]: {
    title: "Occupancy This Month",
    tip: "Your daily occupancy figures for the current month. Update your daily numbers as they occur and/or set a default per day. Occupancy numbers can be set only at a site-level. Select a site in the dropdown at the top of the page.",
    id: WIDGET_IDENTITY.OCCUPANCY,
    previewValue: "000",
  },
  [WIDGET_IDENTITY.MONTHLY_SNAPSHOT]: {
    title: "Monthly Snapshot",
    tip: "Monthly snapshot displaying your waste trends for the current month and previous few months, with comparisons present on the graph and key insights on the left.",
    id: WIDGET_IDENTITY.MONTHLY_SNAPSHOT,
  },
  [WIDGET_IDENTITY.FOCUS_FOOD]: {
    title: "Focus Foods This Month",
    tip: "Widget showing your chosen focus foods and progress of reducing those focus foods this month against set thresholds. To change your focus foods, click ‘edit focus foods.",
    id: WIDGET_IDENTITY.FOCUS_FOOD,
  },
  [WIDGET_IDENTITY.JOURNEY]: {
    title: "Journey to Date",
    tip: "This graph shows the trend of daily waste since installation. The line displays a 7-day rolling average which is calculated by taking the average of a given day’s waste + the six preceding days' waste to provide a clearer trend over time.",
    id: WIDGET_IDENTITY.JOURNEY,
  },
  [WIDGET_IDENTITY.TRIMMINGS]: {
    title: "Trimmings This Month",
    tip: "This month’s waste split by trimmings & preventable",
    id: WIDGET_IDENTITY.TRIMMINGS,
    previewValue: "00%",
  },
  [WIDGET_IDENTITY.MOST_WASTED_GROUPS]: {
    title: "Most Wasted Groups This Month",
    tip: "This month’s waste sorted by most wasted food group.",
    id: WIDGET_IDENTITY.MOST_WASTED_GROUPS,
    previewValue: "#1 Protein",
  },
  [WIDGET_IDENTITY.MINI_PLATE_WASTE]: {
    title: "The Mini Plate Waste Report",
    tip: "This widget summarises plate waste, with key info for this month and an overview of (up to) the top three plate waste monitors by weight. Keep on top of your plate waste reduction progress by setting goals and comparing whether plate waste went up or down compared to last month.",
    id: WIDGET_IDENTITY.MINI_PLATE_WASTE,
  },
  [WIDGET_IDENTITY.DAILY_PLATE_WASTE]: {
    title: 'Daily Plate Waste This Month',
    tip: 'This widget provides a snapshot of plate waste daily, split by traditional service times which can be edited using the link. Use this to pinpoint which service and day to target',
    id: WIDGET_IDENTITY.DAILY_PLATE_WASTE,
  },
}
