import { WIDGET_IDENTITY } from "../config";
import { useWidget } from "../useWidget";
import Stats from "./components/Stats";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { useModal } from "mui-modal-provider";
import { selectExplore } from "../../../redux/explore";
import { selectUser } from "../../../redux/user";
import { formatDataQuery } from "../../../utils/date/formatDataQuery";
import { mepMetricParams } from "../../explore/helpers";
import { useMemo, useRef } from "react";
import { COLORS } from "../../../constants/base";
import Base from "../Base";
import { Stack, Button } from "@mui/material";
import Loader from "../Loader";
import Placeholder from "../components/Placeholder";
import Chart from "./components/Chart";
import { useResizeDetector } from "react-resize-detector";
import SetDailyTargetModal from "./SetDailyTargetModal";
import SimpleDialog from "../../dialogs/SimpleDialog";
import useIsMobile from "../../../hooks/useIsMobile";
import { getChartData, getStatsData } from "./helpers";
import { CHART_TYPE, LABELS, DATE_GRAN } from "../../../constants/explore";
import { MAX_VERTICAL_SIZE } from "./constants";
import { startOfCurrentMonth } from "../../../constants/widgets";

const sixMonthAgo = dayjs().subtract(6, "months").format("YYYY-MM-DD");

export const MiniPlateWaste = () => {
  const { filters } = useSelector(selectExplore);
  const { id } = useSelector(selectUser);
  const selectedSite = filters.sites[0];
  const { showModal } = useModal();
  const isMobile = useIsMobile();
  const baseRef = useRef(null);
  const { width } = useResizeDetector({ targetRef: baseRef });
  const params = useMemo(
    () => ({
      startDate: formatDataQuery(startOfCurrentMonth),
      endDate: formatDataQuery(new Date()),
      metric: mepMetricParams[filters.metric],
      ...(selectedSite?.id ? { site: selectedSite.id } : {}),
    }),
    [filters.metric, selectedSite?.id]
  );

  const {
    goToExplore,
    title,
    tip,
    updateCache,
    data,
    isLoading,
  } = useWidget(WIDGET_IDENTITY.MINI_PLATE_WASTE, {
    fetchKey: id ? [`api/overview/plate_waste_report/`, params] : null,
    exploreParams: {
      params: {
        ...filters,
        dateRange: [formatDataQuery(sixMonthAgo), formatDataQuery(new Date())],
        labels: LABELS.monitors,
        chartType: CHART_TYPE.bar,
        dateGranularity: DATE_GRAN.monthly,
        platesWasteOnly: true,
      },
      path: "/explore",
    },
  });
  const isWide = width > MAX_VERTICAL_SIZE;
  const justify = isWide ? "row" : "column";
  const align = isWide ? "center" : "flex-start";

  const showSettargetModal = () => {
    const targetModal = showModal(SimpleDialog, {
      maxWidth: "xs",
      fullWidth: true,
      fullScreen: isMobile,
      handleClose: () => targetModal.hide(),
      content: (
        <SetDailyTargetModal
          closeModal={() => {
            targetModal.hide();
            updateCache();
          }}
        />
      ),
    });
  };

  const isData = !!data?.chart?.length

  return (
    <Base
      title={title}
      tooltip={tip}
      id={WIDGET_IDENTITY.MINI_PLATE_WASTE}
      ref={baseRef}
      canUnpin
    >
      <Base.Content style={{ minHeight: "280px", position: "relative" }}>
        {isLoading && <Loader />}
        {isData && (
          <Stack
            spacing={8}
            direction={justify}
            justifyContent="space-between"
            alignItems={align}
            width='100%'
          >
            <Stats data={getStatsData(data)} isWide={isWide} />
            <Chart
              unit={data.unit}
              data={getChartData(data.chart) || []}
            />
          </Stack>
        )}
        {data?.chart?.length === 0 && !isLoading && (
          <Placeholder text="No Data"/>
        )}
      </Base.Content>
      <Base.Footer>
        <Stack direction="row" justifyContent="space-between">
          <Button
            disabled={isLoading || !isData}
            variant="link"
            onClick={showSettargetModal}
          >
            Set Plate Waste Targets
          </Button>
          <Button disabled={isLoading || !isData} variant="link" onClick={goToExplore}>
            Explore Plate Waste
          </Button>
        </Stack>
      </Base.Footer>
    </Base>
  );
};
