import { Typography } from "@mui/material";
import numberFormater from "../../../utils/formaters/numberFormater";

export const Recommendation = ({ target, message }) => {
  const displayTarget = target && numberFormater({ value: target, fixed: 2, after: 'kg'})
  return (
    <>
       {!!target && <Typography variant="small1">Recommended Target: {displayTarget}</Typography>}
       {!!message && <Typography variant="small1">{message}</Typography>}
    </>
  )
}

export default Recommendation;
