export const PLATE_WASTE = "Plate Waste";
export const PREP_AND_PRODUCTION_WASTE = "Prep & Production Waste";

export const typeChoicesDict = {
  PLATE_WASTE,
  PREP_AND_PRODUCTION_WASTE,
};

const getTypeChoices = (status) => typeChoicesDict[status] || null;

export default getTypeChoices;
