import { widgetsConfig } from "./config";
import { useSelector } from "react-redux";
import { selectPinnedWidgets } from "../../redux/widgets";
import { selectExplore } from "../../redux/explore";
import { useNavigate } from "react-router-dom";
import useSWR, { mutate } from "swr";
import { fetcher } from "../../utils/axios";
import { useCallback } from "react";
import { propsToParams } from "../explore/storeMappers";
import qs from "qs";
import useIsMobile from "../../hooks/useIsMobile";

export const useWidget = (
  widgetId,
  { isPreview = false, fetchKey = null, exploreParams }
) => {
  const params = widgetsConfig[widgetId];
  const pinnedWidgets = useSelector(selectPinnedWidgets);
  const isPinned = pinnedWidgets.includes(widgetId);
  const { filters } = useSelector(selectExplore);
  const selectedSite = filters.sites[0];
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const title = params
    ? isPreview || isMobile
      ? params?.title
      : `${params?.title} - ${selectedSite?.name || "All Sites"}`
    : "No Title";

  const { data, isLoading } = useSWR(fetchKey, fetcher, {
    revalidateOnFocus: false,
    keepPreviousData: true,
    errorRetryCount: 2,
  });

  const updateCache = () => mutate(fetchKey);

  const goToExplore = useCallback(
    () =>
      exploreParams
        ? navigate({
            pathname: exploreParams.path,
            search: qs.stringify(
              propsToParams({ ...exploreParams.params, food: [], zones: [] })
            ),
          })
        : null,
    [exploreParams]
  );

  if (isPreview) {
    return {
      ...params,
      isPinned,
      title,
    };
  }

  return {
    ...params,
    isPinned,
    title,
    updateCache,
    data,
    isLoading,
    goToExplore,
  };
};
