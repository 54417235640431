import numberFormater from "../../../utils/formaters/numberFormater";
import { SERVICE_TIME } from "./components/ServiceTime";

export const getAvgWaste = (data) => SERVICE_TIME.map(item => numberFormater({
  value: item.getValue(data),
  fixed: 1,
  after: data.unit,
}))

export const getPeakDay = data => SERVICE_TIME.map(item => item.getPeakDay(data))

export const mapData = data => {
  if(!data) {
    return {}
  }
  return {
    breakfast: data.avg_breakfast,
    lunch: data.avg_lunch,
    dinner: data.avg_dinner,
    peakBreakfast: data.peak_breakfast,
    peakLunch: data.peak_lunch,
    peakDinner: data.peak_dinner,
    unit: data.unit
  }
};
