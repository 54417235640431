import * as Yup from 'yup';
import { numberFormater } from "../../../utils/formaters/numberFormater";
import { validThreshold } from "../../../utils/validation/base";

export const getStatsData = (data) => ({
  totalWaste: numberFormater({
    value: data.total_waste || 0,
    fixed: 0,
    after: data.unit,
  }),
  mealsWaste: numberFormater({
    value: data.total_meals || 0,
    fixed: 0,
  }),
  totalWasteDiff: Math.floor(data.total_waste_difference),
  plateWaste: `${Math.floor(data.plate_waste_percentage)}%`,
  plateWasteDiff: data.plate_waste_percentage_difference,
  activeMonitors: data.active_monitors,
});

export const getChartData = (data = []) =>
  data.map((device) => ({
    name: device.name,
    value: device.value,
    monthly_targets: {
      kg: device.threshold,
    },
  }));

export const getThersoldValidationSchema = (payload) => {
  try{
    const shape = Object.keys(payload).reduce((acc, item) => {
      acc[item] = Yup.object().shape({
        threshold: validThreshold
      })
      return acc
    }, {})

    return Yup.object().shape(shape)
  }catch(e) {
    console.log(e)
  }
}

export const mapFormData = data => data.reduce((acc, item) => {
    acc[item.id] = { id: item.id, threshold: item.threshold === null ? 0 :  item.threshold }
    return acc
  }, {})
