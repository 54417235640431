import { Stack, Typography, Box } from "@mui/material";
import React from "react";
import { getDiffColor, getDiffText } from "../../../explore/chart/helpers";

const Diff = ({ value }) => {
  const color = getDiffColor(value);
  return (
    <Stack spacing={2} direction="row" alignItems="center">
      <Box width={15} height={15} borderRadius="50%" backgroundColor={color} />
      <Typography fontWeight={500} fontSize={10} noWrap>
        ({getDiffText(value, Math.floor)})
      </Typography>
    </Stack>
  );
};

export default Diff;
