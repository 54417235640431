import React, { useRef, useState } from 'react';
import {Bar, BarChart, Legend, ResponsiveContainer, Tooltip, XAxis, YAxis} from "recharts";
import {getDiff, getDiffText} from "./helpers";
import {renderTooltip, renderLegend, ExploreBar, TargetBar} from "./renders";
import {COLORS} from "../../../constants/base";
import numberFormater from "../../../utils/formaters/numberFormater";
import {CHART_MODE} from "./constants";
import {targetBarColor} from "../../widgets/MonthlySnapshot/helpers";
import {formatTicker} from "../helpers";
import {Alert} from "../../styled/GlobalStyled";
import {Stack} from "@mui/material";
import {formatCoastValue} from "../../../utils/formaters/formatCoastValue";

const MAX_BARS_LABELS = 8

const BAR_BY_MODE = {
  [CHART_MODE.explore]: ExploreBar,
  [CHART_MODE.snapshot]: TargetBar,
}

const TOOLTIP_COLOR_BY_MODE = {
  [CHART_MODE.explore]: null,
  [CHART_MODE.snapshot]: targetBarColor,
}

const CIRCLE_WIDTH = 20

const CustomBarLabel = ({ x, y, value, index, data, unit, activeLabels, direction, width, height, mode = CHART_MODE.explore, target, formatValues }) => {
  const isVertical = direction === 'vertical'
  const posByMode = {
    [CHART_MODE.explore]: {
      x: isVertical ? x + width : x,
      y:  isVertical ? y + height : y,
    },
    [CHART_MODE.snapshot]: {
      x: x + width/2,
      y: y + height,
    }
  }
  const valueToShow = mode === CHART_MODE.explore ? value : value - target
  const isSnapshot = mode === CHART_MODE.snapshot
  const xPos = posByMode[mode]?.x || x
  const yPos = posByMode[mode]?.y || y
  const diffAll = data[index]?.diff !== undefined ? data[index]?.diff : getDiff(data[index].meta, activeLabels)
  const diff = numberFormater({ value: diffAll, fixed: 2 })
  const color = diffAll > 0 ? COLORS.red : diffAll === 0 ? COLORS.yellow : COLORS.green
  const normalizedValue = formatValues
    ? formatValues(valueToShow, unit)
    : numberFormater({ value: valueToShow, fixed: 2, after: unit })
  return (
    <g>
      {!isSnapshot && (
        <circle cx={xPos + 10} cy={yPos - 30} r={5} fill={color}/>
      )}
      <text x={isSnapshot ? xPos : xPos + CIRCLE_WIDTH} y={isSnapshot ? yPos - 10 : yPos - 25} fill="#666" fontSize={12} textAnchor={isSnapshot ? 'middle' : 'start'}>
        {formatCoastValue(normalizedValue)}
      </text>
      {!isSnapshot && (
        <text x={xPos} y={yPos - 10} fill="#666" fontSize={12}>
          {getDiffText(diffAll, formatValues)}
        </text>
      )}
    </g>
  );
};

const BarChar = ({
   data,
   activeLabels,
   toggleActiveLabel,
   unit,
   direction,
   xKey,
   showLegends = true,
   mode = CHART_MODE.explore,
   duration,
   ticks,
   formatValues,
  ...props
 }) => {
  const allLabels = Object.values(activeLabels)
  const activeBars = allLabels.filter(({ isActive }) => isActive)
  const isVertical = direction === 'vertical'
  const isMultiLabels = Object.keys(activeLabels).length > 1
  const shouldHideLabel = isVertical || (data.length > MAX_BARS_LABELS && !isVertical && mode === CHART_MODE.explore)
  const height = 800
  const minHeight = isVertical ? Math.max(600, height) : 400
  const CustomBar = BAR_BY_MODE[mode]
  const [ground, setGround] = useState(0)
  const scroll = useRef(0)

  const handleTogglelabel = (position) => (value) => {
    scroll.current = position
    toggleActiveLabel(value)
  }


  return (
    <Stack sx={{ height: '100%' }}>
      {isVertical && (
        <Alert mt={5} severity="info">Tap a column on the graph for details</Alert>
      )}
      <ResponsiveContainer width="100%" height="100%" minHeight={ minHeight }>
        <BarChart
          width={500}
          height={300}
          data={data}
          layout={direction}
          margin={{ right: isVertical ? 70 : 40, top: 40, left: isVertical ? 20 : 40, bottom: 40 }}
        >
          <XAxis fontSize={11} dataKey={isVertical ? undefined : xKey } hide={isVertical} type={isVertical ? 'number' : undefined} textAnchor="start" stroke={COLORS.gray_light} angle={45} tickFormatter={formatTicker(duration)} {...ticks} />
          <YAxis fontSize={11} dataKey={isVertical ? xKey : undefined } type={isVertical ? 'category' : undefined } stroke={COLORS.gray_light} tickFormatter={isVertical ? formatTicker(duration) : undefined} />
          {activeBars.length && <Tooltip wrapperStyle={{ pointerEvents: 'auto' }} position={isVertical ? {x: 0} : undefined } content={renderTooltip(activeLabels, unit, activeBars, TOOLTIP_COLOR_BY_MODE[mode], duration, formatValues, mode)} cursor={{fill: 'transparent'}} />}
          {isMultiLabels && showLegends && <Legend wrapperStyle={{ width: isVertical ? 'inherit' : 'unset' }} content={renderLegend(activeLabels, handleTogglelabel, scroll.current)} />}
          {activeBars.map(({key, isActive, color}, index, arr) => (
            <Bar
              maxBarSize={50}
              key={key}
              dataKey={key}
              unit={unit}
              stackId="a"
              shape={<CustomBar fill={color} direction={direction} isFirst={index === 0} changeGround={setGround} ground={ground}/>}
              label={ arr.length -1 === index && !shouldHideLabel && <CustomBarLabel direction={direction} activeLabels={arr} unit={unit} data={data} mode={mode} formatValues={formatValues} />}
            />
          ))}
        </BarChart>
      </ResponsiveContainer>
    </Stack>
  );
};

export default React.memo(BarChar);
