const sprig = window.Sprig

export const setupUserData = ({ id, email, first_name: firstName, surname, employer, role}) => {
    if (sprig) {
        sprig('setUserId', id)
        sprig('setEmail', email)
        sprig('setAttributes', {
            Name: firstName,
            ['Last Name']: surname,
            Employer: employer,
            Role: role,
        })
    }
}

export const track = event => {
    if (sprig) {
        sprig('track', event)
    }
}

export const logout = () => {
    if (sprig) {
        sprig('logoutUser')
    }
}