import React, { useRef } from "react";
import { Stack, Tooltip, Typography } from "@mui/material";
import { COLORS } from "../../../constants/base";
import { defaultFoodImage } from "../../../constants/urls";
import { useTheme } from "@mui/material/styles";
import Diff from "../MiniPlateWaste/components/Diff";

const IMAGE_SIZE = 40;

const Label = ({ Icon, title, description, diff, sx }) => {
  const isString = typeof Icon === "string";
  const imgRef = useRef();
  const theme = useTheme();
  const onImageError = () => {
    if (imgRef.current) {
      imgRef.current.src = defaultFoodImage;
    }
  };
  return (
    <Stack sx={sx} direction="row" alignItems="center" spacing={4}>
      {Icon &&
        (isString ? (
          <img
            ref={imgRef}
            width={IMAGE_SIZE}
            height={IMAGE_SIZE}
            src={Icon}
            onError={onImageError}
          />
        ) : (
          <Icon width={IMAGE_SIZE} height={IMAGE_SIZE} />
        ))}
      <Stack sx={{ minWidth: "0px" }}>
        <Stack spacing={4} direction="row" alignItems="center">
          <Tooltip title={title}>
            <Typography
              color={theme.palette.widget.label}
              textOverflow="ellipsis"
              overflow="hidden"
              variant="medium"
              fontWeight={700}
            >
              {title}
            </Typography>
          </Tooltip>
          {diff !== undefined && <Diff value={diff} />}
        </Stack>
        {description && (
          <Typography
            width="100%"
            textOverflow="ellipsis"
            overflow="hidden"
            variant="subtitle1"
            color={COLORS.inputBorder}
          >
            {description}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};

export default React.memo(Label);
