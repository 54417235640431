import { baseAxios } from "../axios";
import qs from "qs";

export const getRecommendedTarget = (params) => {
  const queryString = qs.stringify({
    ...params,
    widget: params.widget || "monthly_snapshot",
  });

  return baseAxios
    .get(`api/overview/recommended_waste_target/?${queryString}`)
    .then((res) => ({
      target: res.data.proposed_target,
      message: res.data.message,
    }));
};
