import React from 'react';
import {FormControl, InputLabel, MenuItem, Select, TextField} from "@mui/material";
import {CUSTOM_ANSWER} from "./questions";
import {handleWith} from "../../utils/handleWith";

const Dropdown = ({question, name, value, onChange, errors, setErrors}) => {
  const isCustomAnswer = value && !question.answers.some(q => q === value)
  const resetErrors = () => setErrors({})
  return (
    <FormControl key={value} variant="standard">
      <InputLabel sx={{ whiteSpace: 'normal'}} variant="outlined" id={name}>{question.question}</InputLabel>
      <Select
        id={name}
        name={name}
        value={ isCustomAnswer ? question.customAnswerOn : value}
        onChange={handleWith(onChange, resetErrors)}
        variant="outlined"
      >
        {question.answers.map((answer, index) => (
          <MenuItem key={`${name}-${index}`} value={answer}>{answer}</MenuItem>
        ))}
      </Select>
      {((question.customAnswerOn === value) || isCustomAnswer) && (
        <TextField
          name={CUSTOM_ANSWER}
          onChange={onChange}
          error={!!errors[CUSTOM_ANSWER]}
          helperText={errors[CUSTOM_ANSWER]}
          InputLabelProps={{ shrink: true }}
          defaultValue={isCustomAnswer ? value : undefined}
          placeholder="Type answer..."
        />
      )}
    </FormControl>
  );
};

export default Dropdown;
